import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { Input, Label } from '../form';
import FieldTooltip from '../tooltip/FieldTooltip';

export const CheckBoxContainer = styled('div')`
  align-content: center;
  align-items: ${({ inline }) => (inline ? 'center' : 'flex-start')};
  justify-content: ${({ inline }) => (inline ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row-reverse' : 'column')} !important;

  label {
    margin-bottom: 0px;
  }
`;

const CheckBoxInput = styled(Input)`
  margin: 0 .5rem 0 0;
  height: 34px;
`;

const CheckBoxField = ({ id, inline, label, field, tooltipLabel, intl: { formatMessage }, ...restProps }) => (
  <>
  <CheckBoxContainer inline={inline}>
    {label && (
      <Label htmlFor={id || (field && field.name)}>
        <FormattedMessage id={label} defaultMessage={label} />
      </Label>
    )}
    <CheckBoxInput
      {...field}
      {...restProps}
      value={field.value || ''}
      type="checkbox"
      checked={field.value || false}
      id={id || (field && field.name)}
    />
  </CheckBoxContainer>
  {tooltipLabel && (
    <FieldTooltip
      placement="right"
      content={formatMessage({ id: tooltipLabel })}
      iconColor="gray"
      iconSize="sm"
      right="-112px"
      minWidth="150px"
    />
  )}
  </>
);

export default injectIntl(CheckBoxField);
