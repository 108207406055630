import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components/macro';
import { object, string } from 'yup';
// redux
import { requestProformaSimulation } from '../../actions/paymentActions';
import {
  CURRENCY_TABLE,
  FUNCTION_LEVEL_TABLE,
  JOB_TITLE_TABLE,
  ANONYMOUS_REGULATION_TABLE,
  STATION_TABLE,
} from '../../constants/dataTableIds';
// const
import { INTERNAL_DATE } from '../../constants/dateFormats';
import { REGULATION_ACRU, REGULATION_RCEV, REGULATION_UVCN } from '../../constants/regulations';
// Form
import CheckBoxField from '../_form/CheckboxField';
import CleaveField from '../_form/CleaveField';
import DataTableSelectField from '../_form/DataTableSelectField';
import RadioButtonField from '../_form/RadioButtonField';
import RegulationHeading from '../employee/dossier/RegulationHeading';
// Components
import DossierSkeleton from '../employee/DossierSkeleton';
import { Button, ButtonGroup, Icon } from '../layout';
// Layouts
import Alert from '../layout/Alert';
import FormGroup from '../layout/FormGroup';
import HeadingFieldset from '../layout/HeadingFieldset';

const StyledProformaDossier = styled('div')`
  position: relative;
  margin: 50px 20px;
`;

const Heading = styled('div')`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.default};
  margin-bottom: 10px;
`;

const initialValues = {
  between4And21: '',
  between12And25: '',
  car: false,
  contractHours: '',
  functionLevel: '',
  jobTitle: '',
  monthlyRent: '',
  partner: 'false',
  partnerStatus: '0',
  regulation: '',
  rentCurrency: '',
  residence: false,
  residenceEmbassy: false,
  salaryGross: '',
  salaryNet: '',
  shortMission: false,
  station: '',
  under4: '',
  under12: '',
  transportCar: false,
};

const regulationSpecifics = {
  car: [REGULATION_ACRU, REGULATION_RCEV, REGULATION_UVCN],
  children: [REGULATION_ACRU, REGULATION_UVCN],
  functionLevelTitle: [REGULATION_ACRU],
  monthlyRent: [REGULATION_UVCN],
  partner: [REGULATION_ACRU, REGULATION_UVCN],
  rentCurrency: [REGULATION_UVCN],
  residence: [REGULATION_ACRU],
  residenceEmbassy: [REGULATION_ACRU],
  under4: [REGULATION_UVCN],
  under12: [REGULATION_ACRU],
  between4And21: [REGULATION_UVCN],
  between12And25: [REGULATION_ACRU],
  transportCar: [REGULATION_UVCN],
  shortMission: [REGULATION_UVCN]
};

const fieldIsInRegulation = (field: string, regulation: string): boolean => (
  regulationSpecifics[field].indexOf(regulation) !== -1
);

const proformaValidationSchema = object().shape({
  functionLevel: string().when('regulation', (regulation) => (fieldIsInRegulation('functionLevelTitle', regulation) ? string().required('message.required') : string())),
  jobTitle: string().when('regulation', (regulation) => (
    fieldIsInRegulation('functionLevelTitle', regulation) ? string().required('message.required') : string())),
  regulation: string().required('message.required'),
  salaryGross: string().required('message.required'),
  salaryNet: string().required('message.required'),
  station: string().required('message.required'),
});

const mapToString = (values) => {
  const startOfMonth = moment().startOf('month').format(INTERNAL_DATE) < '2025-01-01' ? '2025-01-01' : moment().startOf('month').format(INTERNAL_DATE);
  const endOfMonth = moment().endOf('month').format(INTERNAL_DATE) < '2025-01-31' ? '2025-01-31' : moment().endOf('month').format(INTERNAL_DATE);
  const val = {
    ...values,
    anonymous: true,
    debug: true,
    end_date: endOfMonth,
    reference_date: startOfMonth,
    start_date: startOfMonth,
  };
  Object.keys(val).forEach((key) => {
    switch (typeof val[key]) {
      case 'object':
      case 'undefined':
        break;
      case 'string':
        val[key] = `"${val[key]}"`;
        break;
      default:
        val[key] = val[key].toString();
        break;
    }
  });
  return val;
};

const partnerOptions = [
  { label: 'label.zonder_partner', value: 'false' },
  { label: 'label.met_partner', value: 'true' },
];

const partnerStatusesACRU = [
  { label: 'label.with_partner', value: '2' },
  { label: 'label.in_netherlands_without_reasons', value: '1' },
  { label: 'label.in_netherlands_with_reasons', value: '0' },
];

const partnerStatusesUVCN = [
  { label: 'label.with_partner', value: '1' },
  { label: 'label.in_netherlands', value: '0' },
];

const AnonymousProformaForm = ({ loader, simulateProforma }) => (
  <StyledProformaDossier>
    {loader && (<DossierSkeleton />)}
    <Row>
      <Col xs={12} lg={8}>
        <Heading>
          <FormattedMessage id="title.calculate_allowance" />
        </Heading>
      </Col>
    </Row>
    <Alert type="info">
      <FormattedMessage id="message.anonymous_proforma" />
      <br />
      <FormattedMessage id="message.contact_proforma" values={{ url: 'https://www.sso3w.nl/contact' }} />
    </Alert>
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={proformaValidationSchema}
      onSubmit={(values) => simulateProforma(mapToString(values), moment().format(INTERNAL_DATE))}
    >
      {({ isValid, isSubmitting, values: { regulation, partner } }) => (
        <Form>
          {/* REGULATION */}
          <FormGroup row>
            <Col xs={12} lg={4}>
              <Field
                component={DataTableSelectField}
                tableId={ANONYMOUS_REGULATION_TABLE}
                labelColumn="Omschrijving"
                valueColumn="Code"
                label="label.regulation"
                name="regulation"
                filterOptions={({ record: { Code } }) => Code !== 'KU' && Code !== 'VUBZK' && Code !== 'VBRA'}
                required
              />
            </Col>
          </FormGroup>
          {regulation && (
            <>
              <RegulationHeading />
              {/* STATION */}
              <FormGroup row>
                <Col xs={12} lg={6}>
                  <Field
                    component={DataTableSelectField}
                    tableId={STATION_TABLE}
                    labelColumn="Omschrijving"
                    valueColumn="Code"
                    label="label.station"
                    name="station"
                    required
                  />
                </Col>
              </FormGroup>
              {/* SALARY */}
              <FormGroup row>
                <Col xs={12} lg={3}>
                  <Field
                    component={CleaveField}
                    name="salaryGross"
                    label="label.anonymous_gross_salary"
                    required
                    tooltipLabel="tooltip.monthly_salary"
                    options={{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralDecimalScale: 2,
                      numeralThousandsGroupStyle: 'none',
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <Field
                    component={CleaveField}
                    name="salaryNet"
                    label="label.anonymous_net_salary"
                    required
                    tooltipLabel="tooltip.monthly_salary"
                    options={{
                      numeral: true,
                      numeralDecimalMark: ',',
                      numeralDecimalScale: 2,
                      numeralThousandsGroupStyle: 'none',
                    }}
                  />
                </Col>
              </FormGroup>
              {/* FUNCTION LEVEL & TITLE */}
              { fieldIsInRegulation('functionLevelTitle', regulation) && (
                <FormGroup row>
                  <Col xs={4} md={3} xl={2}>
                    <Field
                      component={DataTableSelectField}
                      tableId={FUNCTION_LEVEL_TABLE}
                      labelColumn="Beschrijving"
                      valueColumn="Schaal"
                      label="label.rank"
                      name="functionLevel"
                      required
                    />
                  </Col>
                  <Col xs={8} md={6} xl={4}>
                    <Field
                      component={DataTableSelectField}
                      tableId={JOB_TITLE_TABLE}
                      labelColumn="Omschrijving"
                      valueColumn="Code"
                      label="label.job_title"
                      name="jobTitle"
                      required
                    />
                  </Col>
                </FormGroup>
              )}

              {/* RESIDENCE */}
              {(regulation === REGULATION_RCEV || regulation === REGULATION_ACRU || regulation == REGULATION_UVCN) && (
                <FormGroup row>
                  {fieldIsInRegulation('residence', regulation) && (
                    <Col xs={4} xl={2}>
                      <Field
                        component={CheckBoxField}
                        label="label.residence"
                        name="residence"
                      />
                    </Col>
                  )}
                  {fieldIsInRegulation('residenceEmbassy', regulation) && (
                    <Col xs={4} xl={2}>
                      <Field
                        component={CheckBoxField}
                        label="label.residence_embassy"
                        name="residenceEmbassy"
                      />
                    </Col>
                  )}
                  {fieldIsInRegulation('car', regulation) && (
                    <Col xs={4} xl={2}>
                      <Field
                        component={CheckBoxField}
                        label="label.car"
                        name="car"
                      />
                    </Col>
                  )}
                  {fieldIsInRegulation('transportCar', regulation) && (
                    <Col xs={4} xl={2}>
                      <Field
                        component={CheckBoxField}
                        label="label.transport_car"
                        name="transportCar"
                        tooltipLabel="tooltip.transport_car"
                      />
                    </Col>
                  )}
                </FormGroup>
              )}
                <FormGroup row>
                {fieldIsInRegulation('rentCurrency', regulation) && (
                  <Col xs={6} md={4} xl={3}>
                    <Field
                      component={DataTableSelectField}
                      tableId={CURRENCY_TABLE}
                      labelColumn="Omschrijving"
                      valueColumn="Code"
                      label="label.rent_currency"
                      name="rentCurrency"
                    />
                  </Col>
                )}
                {fieldIsInRegulation('monthlyRent', regulation) && (
                  <Col xs={6} md={4} xl={3}>
                    <Field
                      component={CleaveField}
                      label="label.monthly_rent"
                      name="monthlyRent"
                      options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        numeralDecimalScale: 2,
                        numeralThousandsGroupStyle: 'none',
                      }}
                    />
                  </Col>
                )}
              </FormGroup>
              <FormGroup row>
              {fieldIsInRegulation('shortMission', regulation) && (
                    <Col xs={4} xl={2}>
                      <Field
                        component={CheckBoxField}
                        label="label.short_mission"
                        name="shortMission"
                      />
                    </Col>
                  )}
                  </FormGroup>
              {/* PARTNER */}
              {fieldIsInRegulation('partner', regulation) && (
                <>
                  <HeadingFieldset>
                    <Icon name="People" size="lg" color="gray" />
                    <FormattedMessage id="title.partner" />
                  </HeadingFieldset>
                  <FormGroup row>
                    <Col xs={12} md={9} xl={6}>
                      <Field
                        component={RadioButtonField}
                        options={partnerOptions}
                        label="label.proforma_met_partner"
                        name="partner"
                        tooltipLabel="tooltip.legal_definition_partner"
                      />
                    </Col>
                  </FormGroup>
                  {partner === 'true' && (
                    <>
                      <FormGroup row>
                        <Col xs={12} md={9} xl={6}>
                          <Field
                            component={RadioButtonField}
                            options={regulation == REGULATION_UVCN ? partnerStatusesUVCN : partnerStatusesACRU }
                            label="label.partner_status"
                            name="partnerStatus"
                            tooltipLabel={regulation === REGULATION_ACRU ? 'tooltip.recognized_reasons' : null}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </>
              )}
              {/* CHILDREN */}
              {fieldIsInRegulation('under4', regulation) && fieldIsInRegulation('between4And21', regulation) && (
                <>
                  <HeadingFieldset>
                    <Icon name="Group" size="lg" color="gray" />
                    <FormattedMessage id="title.children" />
                  </HeadingFieldset>
                  <h3><FormattedMessage id="label.children_with_employee" /></h3>
                  <FormGroup row>
                    <Col xs={6} lg={3}>
                      <Field
                        component={CleaveField}
                        name="under4"
                        label="label.children_under_4"
                        options={{
                          numeral: true,
                          numeralDecimalScale: 0,
                          numeralThousandsGroupStyle: 'none',
                        }}
                      />
                    </Col>
                    <Col xs={6} lg={3}>
                      <Field
                        component={CleaveField}
                        name="between4And21"
                        label="label.children_between_4_and_21"
                        options={{
                          numeral: true,
                          numeralDecimalScale: 0,
                          numeralThousandsGroupStyle: 'none',
                        }}
                        tooltipLabel="tooltip.uvcn_conditions_children"
                      />
                    </Col>
                  </FormGroup>
                </>
              )}
              {fieldIsInRegulation('under12', regulation) && fieldIsInRegulation('between12And25', regulation) && (
                <>
                  <HeadingFieldset>
                    <Icon name="Group" size="lg" color="gray" />
                    <FormattedMessage id="title.children" />
                  </HeadingFieldset>
                  <h3><FormattedMessage id="label.children_with_employee" /></h3>
                  <FormGroup row>
                    <Col xs={6} lg={3}>
                      <Field
                        component={CleaveField}
                        name="under12"
                        label="label.children_under_12"
                        options={{
                          numeral: true,
                          numeralDecimalScale: 0,
                          numeralThousandsGroupStyle: 'none',
                        }}
                      />
                    </Col>
                    <Col xs={6} lg={3}>
                      <Field
                        component={CleaveField}
                        name="between12And25"
                        label="label.children_between_12_and_25"
                        options={{
                          numeral: true,
                          numeralDecimalScale: 0,
                          numeralThousandsGroupStyle: 'none',
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormattedMessage id="helptext.special_statusses_children_acru" />
                </>
              )}
            </>
          )}
          <ButtonGroup>
            <Button type="submit" disabled={!isValid || isSubmitting}>
              <FormattedMessage id="button.calculate" />
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  </StyledProformaDossier>
);

const mapDispatch = (dispatch) => ({
  simulateProforma: (values, date) => dispatch(requestProformaSimulation(values, date)),
});

export default connect(null, mapDispatch)(AnonymousProformaForm);
