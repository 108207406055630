import { FIELD_USER, FIELD_DECIMAL, FIELD_EMPLOYEE } from '../constants/fields';
import { ROLE_USER, ROLE_PAYER } from '../constants/roles';

export default {
  tandemId: {
    label: 'label.tandempartner',
    field: FIELD_EMPLOYEE
  },
  manager: {
    label: 'label.manager',
    field: FIELD_USER,
    roles: [ROLE_USER, ROLE_PAYER],
    includeCurrent: true,
  },
  'regulationSpecifics.monthlyRent': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.dailyRent': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.contractHours': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.salaryGross': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.salaryNet': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.preMissionSalaryGross': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.preMissionSalaryNet': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.localSalary': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.preMissionContractHours': {
    field: FIELD_DECIMAL
  },
  'regulationSpecifics.stationTemporary': {
    tableId: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
  },
  'regulationSpecifics.personType': {
    tableId: '90d50986-5994-4b9d-a9c1-8e75a8542be2',
  },
  'regulationSpecifics.employer': {
    tableId: 'd28e87fc-91b7-4bef-a422-1c98a7e3b49d',
  },
  station: {
    tableId: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
  },
  'partner.station': {
    tableId: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
  },
  'children.station': {
    tableId: 'd37b14c7-f9e7-4103-a8ca-0ee844a50f37',
  },
  status: {
    tableId: '890449cd-ca3b-4888-9989-8fbb34188eb4',
  },
  'children.status': {
    tableId: '5faf8a9b-928a-43d0-bccd-3bb71666cc71',
  },
};
